<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
        >
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                xl="12"
                md="12"
              >
                <b-button
                  variant="primary"
                  @click="downloadGameUserTemplate()"
                >
                  <span> {{ $t('DOWNLOAD_USER_GAME_TEMPLATE') }}   </span>
                  <feather-icon icon="DownloadIcon" />
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
        >
          <b-card-body>
            <validation-observer ref="validationRules">
              <b-form
                @submit.prevent="onSubmit"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <!-- Company -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('COMPANY')"
                      label-for="id-company"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('COMPANY')"
                        rules="required"
                      >
                        <v-select
                          id="id-company"
                          v-model="company"
                          :placeholder="$t('SELECT_COMPANY')"
                          label="companyname"
                          class="flex-grow-1"
                          :clearable="false"
                          :options="companies"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- File -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('SELECT_FILE')+ ' (*.xlsx)'"
                      label-for="id-file"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('SELECT_FILE')+ ' (*.xlsx)'"
                        rules="required"
                      >
                        <b-form-file
                          v-model="selectedFile"
                          :placeholder="$t('CHOOSE_FILE_OR_DROP')"
                          drop-placeholder="Drop file here..."
                          accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- strat date -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('START_DATE')"
                      label-for="id-strat-date"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('START_DATE')"
                        rules="required"
                      >
                        <flat-pickr
                          id="id-strat-date"
                          v-model="competition.startdate"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- end date -->
                  <b-col
                    lg="6"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('END_DATE')"
                      label-for="id-end-date"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('END_DATE')"
                        rules="required"
                      >
                        <flat-pickr
                          id="id-end-date"
                          v-model="competition.enddate"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Category -->
                  <b-col
                    lg="6"
                    md="6"
                  >

                    <b-form-group
                      :label="$t('CATEGORY')"
                      label-for="id-category"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('CATEGORY')"
                        rules="required"
                      >
                        <v-select
                          id="id-category"
                          v-model="category"
                          :placeholder="$t('SELECT_CATEGORY')"
                          label="name"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="categoryOptions"
                          @input="fetchTemplates($event)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider></b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    md="6"
                  >

                    <b-form-group
                      :label="$t('CONTENT_OF_THE_GAME')"
                      label-for="id-content"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('CONTENT_OF_THE_GAME')"
                        rules="required"
                      >
                        <v-select
                          id="id-content"
                          v-model="template"
                          :placeholder="$t('SELECT_CONTENT')"
                          label="name"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="templateOptions"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider></b-form-group>
                  </b-col>
                  <!-- vision-statement -->
                  <b-col
                    lg="12"
                    md="12"
                  >
                    <b-form-group
                      :label="$t('VISION_STATEMENT')"
                      label-for="mc-vision-statement"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('VISION_STATEMENT')"
                        rules="required"
                      >
                        <b-form-input
                          id="mc-vision-statement"
                          v-model="competition.visionstatement"
                          maxlength="800"
                          :placeholder="$t('VISION_STATEMENT')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- submit -->
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{ $t('BUTTON.SUBMIT') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                    >
                      {{ $t('BUTTON.RESET') }}
                    </b-button>
                  </b-col>

                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody,
  BButton, BForm, BFormGroup,
  BFormFile, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required } from '@validations'
// eslint-disable-next-line import/no-extraneous-dependencies
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapActions, mapGetters } from 'vuex'
// eslint-disable-next-line import/extensions, import/no-unresolved
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import constants from '@/constants/static.json'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormFile,
    BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      selectedFile: null,
      required,
      company: '',
      companies: [],
      category: '',
      template: '',
      categoryOptions: [],
      templateOptions: [],
      competition: {
        ID: 0,
        c_string_swe: '',
        color: '#127bdc',
        companyId: '',
        startdate: '',
        enddate: '',
        visionstatement: constants.GAME_VISION,
        encrypt_id: '',
        template_id: 0,
        gametype: 0,
      },
    }
  },
  async created() {
    await this.fetchCompany()
    this.fetchCategory()
  },
  setup() {
    const {
      successMessage, errorMessage, showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters('company', ['getAllCompanies']),
    ...mapGetters('template', ['getAllTemplates', 'getAllCategories']),
  },
  methods: {
    ...mapActions('company', ['fetchAllCompanies']),
    ...mapActions('template', ['fetchAllCategories', 'fetchAllTemplates']),
    ...mapActions('user', ['uploadUsers']),
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          if (this.selectedFile) {
            const payload = {
              companyID: this.company.companyID,
              file: this.selectedFile,
              startdate: this.competition.startdate,
              enddate: this.competition.enddate,
              template_id: this.template.ID,
              category_id: this.category.ID,
              visionstatement: this.competition.visionstatement,
            }
            this.uploadUsers(payload).then(response => {
              if (response) {
                this.selectedFile = null
                this.company = ''
                this.competition.startdate = ''
                this.competition.enddate = ''
                this.template = ''
                this.category = ''
                this.successMessage(this.$i18n.t('MESSAGE.UPLOAD_GAME_AND_USER_SUCCESSFULLY'))
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        }
      })
    },
    fetchCategory() {
      this.fetchAllCategories().then(response => {
        if (response) {
          this.categoryOptions = this.getAllCategories
        }
      })
    },
    fetchTemplates(e) {
      this.templateOptions = []
      this.template = ''
      if (e.ID) {
        const params = `?category_id=${e.ID}&type=game`
        this.fetchAllTemplates(params).then(response => {
          if (response) {
            this.templateOptions = this.getAllTemplates.data
          }
        })
      }
    },
    downloadGameUserTemplate() {
      const fileUrl = `${process.env.VUE_APP_HOST}files/bisevo-game-user-template.xlsx`
      const link = document.createElement('a')
      link.href = fileUrl
      link.click()
    },
    async fetchCompany() {
      this.fetchAllCompanies().then(response => {
        if (response) {
          this.companies = this.getAllCompanies
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
