var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"invoice-add-wrapper"},[_c('b-row',{staticClass:"invoice-add"},[_c('b-col',{attrs:{"cols":"12","xl":"12","md":"12"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"12","md":"12"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.downloadGameUserTemplate()}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('DOWNLOAD_USER_GAME_TEMPLATE'))+" ")]),_c('feather-icon',{attrs:{"icon":"DownloadIcon"}})],1)],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"12","md":"12"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('validation-observer',{ref:"validationRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('COMPANY'),"label-for":"id-company"}},[_c('validation-provider',{attrs:{"name":_vm.$t('COMPANY'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-company","placeholder":_vm.$t('SELECT_COMPANY'),"label":"companyname","clearable":false,"options":_vm.companies},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('SELECT_FILE')+ ' (*.xlsx)',"label-for":"id-file"}},[_c('validation-provider',{attrs:{"name":_vm.$t('SELECT_FILE')+ ' (*.xlsx)',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":_vm.$t('CHOOSE_FILE_OR_DROP'),"drop-placeholder":"Drop file here...","accept":".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},model:{value:(_vm.selectedFile),callback:function ($$v) {_vm.selectedFile=$$v},expression:"selectedFile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('START_DATE'),"label-for":"id-strat-date"}},[_c('validation-provider',{attrs:{"name":_vm.$t('START_DATE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"id-strat-date"},model:{value:(_vm.competition.startdate),callback:function ($$v) {_vm.$set(_vm.competition, "startdate", $$v)},expression:"competition.startdate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('END_DATE'),"label-for":"id-end-date"}},[_c('validation-provider',{attrs:{"name":_vm.$t('END_DATE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"id-end-date"},model:{value:(_vm.competition.enddate),callback:function ($$v) {_vm.$set(_vm.competition, "enddate", $$v)},expression:"competition.enddate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CATEGORY'),"label-for":"id-category"}},[_c('validation-provider',{attrs:{"name":_vm.$t('CATEGORY'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-category","placeholder":_vm.$t('SELECT_CATEGORY'),"label":"name","clearable":false,"options":_vm.categoryOptions},on:{"input":function($event){return _vm.fetchTemplates($event)}},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CONTENT_OF_THE_GAME'),"label-for":"id-content"}},[_c('validation-provider',{attrs:{"name":_vm.$t('CONTENT_OF_THE_GAME'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-content","placeholder":_vm.$t('SELECT_CONTENT'),"label":"name","clearable":false,"options":_vm.templateOptions},model:{value:(_vm.template),callback:function ($$v) {_vm.template=$$v},expression:"template"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('VISION_STATEMENT'),"label-for":"mc-vision-statement"}},[_c('validation-provider',{attrs:{"name":_vm.$t('VISION_STATEMENT'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-vision-statement","maxlength":"800","placeholder":_vm.$t('VISION_STATEMENT')},model:{value:(_vm.competition.visionstatement),callback:function ($$v) {_vm.$set(_vm.competition, "visionstatement", $$v)},expression:"competition.visionstatement"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('BUTTON.SUBMIT'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.$t('BUTTON.RESET'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }